import { COUNTRY_NAMES } from 'src/app/shared/static/countries';
import { genders } from 'src/app/shared/static/genders';
import { LANGUAGES } from 'src/app/shared/static/languages';
import { RELIGIONS } from 'src/app/shared/static/religions';
import { ResidentPreferenceConfig } from '../models/model';

export const profileConfig: ResidentPreferenceConfig = {
  order: 1,
  header: {
    title: 'About Me',
    icon: 'account_circle',
    button: {
      icon: 'edit_note',
      label: 'Edit'
    }
  },
  fields: {
    firstName: {
      hideOnCard: true,
      type: 'TEXT',
      label: 'Resident first name',
      placeholder: 'First Name',
      required: true,
    },
    lastName: {
      hideOnCard: true,
      type: 'TEXT',
      label: 'Resident last name',
      placeholder: 'Last Name',
      required: true,
    },
    preferredName: {
      hideOnCard: true,
      type: 'TEXT',
      label: 'Resident preferred name',
      placeholder: 'Preferred Name',
    },
    roomNumber: {
      cardTitle: 'Room number',
      type: 'TEXT',
      label: 'Room number',
      placeholder: 'Room number',
      required: true,
    },
    dob: {
      cardTitle: 'Date of birth',
      type: 'DATE',
      hideOnCard: true,
      label: 'Date of birth',
      placeholder: '27/02/1932',
      required: true,
    },
    gender: {
      type: 'CHIPS',
      label: 'Gender',
      multiple: false,
      options: genders,
      required: true,
    },
    countryOfBirth: {
      cardTitle: 'Country of birth',
      type: 'AUTOCOMPLETE',
      label: 'What country did they grow up in?',
      subLabel: 'Where they lived aged 0-10',
      options: COUNTRY_NAMES.map(val => ({ viewValue: val.label, value: val.code})),
      required: true,
      distinct: true,
    },
    primaryLanguage: {
      cardTitle: 'Language',
      type: 'AUTOCOMPLETE',
      label: 'What is their primary language?',
      options: LANGUAGES.map(val => ({ viewValue: val.languageName, value: val.languageCode})),
      required: true,
      defaultValue: 'EN',
      distinct: true,
    },
    religion: {
      cardTitle: 'Religious / spiritual',
      type: 'SELECT',
      label: 'Do they have any religious or spirituality preferences?',
      options: RELIGIONS.map(val => ({ viewValue: val.viewValue, value: val.value})),
    },
    suggestedReligion : {
      cardTitle: 'Other religions',
      type: 'CHIP_INPUT',
      label: 'Can’t find religion above?',
      subLabel: 'Enter religion and click +',
      placeholder: 'Enter other religion',
      notifySlack: 'residentsuggestions',
    },
    practiceReligion : {
      type: 'TOGGLE',
      label: 'Do they regularly practice their faith?',
      text: 'Yes',
    },
  }
}