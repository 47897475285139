import { ResidentPreferenceConfig } from '../models/model';

export const interestsConfig: ResidentPreferenceConfig = {
  order: 2,
  header: {
    title: 'Interests & Hobbies %firstName% likes',
    icon: 'interests',
    button: {
      icon: 'edit_note',
      label: 'Edit'
    }
  },
  fields: {
    interests: {
      type: 'CHIPS',
      cardTitle: 'Interests & Hobbies',
      multiple: true,
      query: 'getCategories',
      queryParams: {
        slug: '/interests-and-hobbies',
        type: 'VIDEO',
      },
      label: 'Do they have any hobbies or specific interests?',
      subLabel: 'Select all that apply',
      defaultValue: [],
    },

    suggestedInterests: {
      cardTitle: 'Other interests',
      type: 'CHIP_INPUT',
      label: 'Add more hobbies or interests?',
      subLabel: 'Enter interest or hobby name and click +',
      notifySlack: 'residentsuggestions',
      defaultValue: [],
    },
    memories: {
      type: 'TEXTAREA',
      label: 'Thoughts & memories',
      subLabel: 'Do they have any specific memories about their interests?'
    }
  }
}