import { ResidentPreferenceConfig } from '../models/model';

export const petsConfig: ResidentPreferenceConfig = {
  order: 6,
  header: {
    title: 'Pets & Animals %firstName% likes',
    icon: 'pets',
    button: {
      icon: 'edit_note',
      label: 'Edit'
    }
  },
  fields: {
    pets: {
      cardTitle: 'Pets & Animals',
      type: 'CHIPS',
      multiple: true,
      query: 'getCategories',
      queryParams: {
        slug: '/pets-and-animals',
        type: 'VIDEO',
      },
      label: 'Did they have any pets or animal fondness?',
      subLabel: 'Select all that apply',
      defaultValue: [],
    },
    suggestedPets: {
      cardTitle: 'Other pets and animals',
      type: 'CHIP_INPUT',
      label: 'Can’t find your favourite pet or animal?',
      subLabel: 'Type pet name and click +',
      placeholder: 'Enter pet name',
      notifySlack: 'residentsuggestions',
      defaultValue: [],
    },
    memories: {
      type: 'TEXTAREA',
      label: 'Memories',
      subLabel: 'Do they remember any specific pets? What were their names, types and breeds?'
    }
  }
}