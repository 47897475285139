import { ResidentPreferenceConfig } from '../models/model';

export const occupationsConfig: ResidentPreferenceConfig = {
  order: 3,
  header: {
    title: 'My Occupation',
    icon: 'workspace_premium',
    button: {
      icon: 'edit_note',
      label: 'Edit'
    }
  },
  fields: {
    industry: {
      cardTitle: 'Industry',
      type: 'CHIPS',
      multiple: true,
      query: 'getCategories',
      queryParams: {
        slug: '/occupations',
        type: 'VIDEO',
      },
      label: 'What industry(s) did they work in?',
      defaultValue: [],
    },
    suggestedOccupations: {
      cardTitle: 'Other occupations',
      type: 'CHIP_INPUT',
      label: 'Can’t find your occupation?',
      subLabel: 'Type the occupation name and click +',
      placeholder: 'Enter occupation name',
      notifySlack: 'residentsuggestions',
      defaultValue: [],
    },
    memories: {
      type: 'TEXTAREA',
      label: 'Thoughts & memories',
      subLabel: 'Do they have specific work memories?'
    }
  }
}