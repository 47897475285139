import { ResidentPreferenceConfig } from '../models/model';

export const notesConfig: ResidentPreferenceConfig = {
  order: 7,
  header: {
    title: 'General Notes',
    icon: 'info',
    button: {
      icon: 'edit_note',
      label: 'Edit'
    }
  },
  fields: {
    notes: {
      type: 'TEXTAREA',
      label: 'General notes',
      subLabel: 'Any other memories or details that are relevant to the resident.'
    }
  }
}