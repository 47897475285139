import { ResidentFormfields, ResidentPreferenceConfig } from '../models/model';
import { interestsConfig } from './interests.config';
import { musicConfig } from './music.config';
import { notesConfig } from './notes.config';
import { occupationsConfig } from './occupations.config';
import { petsConfig } from './pets.config';
import { profileConfig } from './profile.config';
import { sportsConfig } from './sports.config';

/**
 * Fetch the config for the provided slug and return a deep copy of the config object
 */
export const getResidentPreferenceConfig = (slug: string): ResidentPreferenceConfig | undefined => {
  switch (slug) {
    case 'music':
      return JSON.parse(JSON.stringify(musicConfig));

    case 'interests':
      return JSON.parse(JSON.stringify(interestsConfig));

    case 'occupations':
      return JSON.parse(JSON.stringify(occupationsConfig));

    case 'sports':
      return JSON.parse(JSON.stringify(sportsConfig));

    case 'pets':
      return JSON.parse(JSON.stringify(petsConfig));

    case 'notes':
      return JSON.parse(JSON.stringify(notesConfig));

    case 'profile':
      return JSON.parse(JSON.stringify(profileConfig));
  }
  return undefined;
}

export const configs = [interestsConfig, musicConfig, notesConfig, occupationsConfig, petsConfig, profileConfig, sportsConfig];

/**
 * Extracdt all queries from config fields
 */
export const getAllFieldQueries = (): {[key: string]: ResidentFormfields} => {
  const queries: {[key: string]: ResidentFormfields} = {};
  for (const config of configs) {
    Object.entries(config.fields).filter(([key, field]) => field.query).forEach(val => {
      queries[val[0]] = val[1];
    });
  }
  return queries;
}