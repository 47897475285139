export const RELIGIONS = [
  { viewValue: "Anglican (C of E)", value: "anglican" },
  { viewValue: "Baha’i", value: "bahai" },
  { viewValue: "Baptist", value: "baptist" },
  { viewValue: "Buddhism", value: "buddhism" },
  { viewValue: "Catholic", value: "catholic" },
  { viewValue: "Christian", value: "christian" },
  { viewValue: "Hindu", value: "hindu" },
  { viewValue: "Islam", value: "islam" },
  { viewValue: "Jehovah’s Witness", value: "jehovahs-witness" },
  { viewValue: "Judaism", value: "judaism" },
  { viewValue: "Lutheran", value: "lutheran" },
  { viewValue: "Methodist", value: "methodist" },
  { viewValue: "Orthodox", value: "orthodox" },
  { viewValue: "Other", value: "other" },
  { viewValue: "Presbyterian", value: "presbyterian" },
  { viewValue: "Protestant", value: "protestant" },
  { viewValue: "Seventh Day Adventist", value: "seventh-day-adventist" },
  { viewValue: "Spiritual", value: "spiritual" },
  { viewValue: "Taoism", value: "taoism" },
  { viewValue: "Uniting", value: "uniting" }
];
