import { ResidentPreferenceConfig } from '../models/model';

export const musicConfig: ResidentPreferenceConfig = {
  order: 4,
  header: {
    title: 'Music %firstName% likes',
    svgIcon: 'ap_music',
    button: {
      icon: 'edit_note',
      label: 'Edit'
    }
  },
  fields: {
    genre: {
      cardTitle: 'Music Genre',
      type: 'CHIPS',
      query: 'getCategories',
      multiple: true,
      queryParams: {
        slug: '/music-categories',
        type: 'MUSIC',
      },
      label: 'What genre(s) of music do they like?',
      subLabel: 'Select all that apply',
      defaultValue: [],
    },
    favouriteArtists: {
      cardTitle: 'Artists',
      type: 'AUTOCOMPLETE_CHIP',
      query: 'getArtists',
      queryParams: {},
      label: 'Who are their favourite musicians or artists?',
      subLabel: 'Search and select multiple artists.',
      placeholder: 'Enter singers & artists name',
      defaultValue: [],
    },
    suggestedArtist: {
      cardTitle: 'Other artists',
      type: 'CHIP_INPUT',
      label: 'Can’t find your favourite artist?',
      subLabel: 'Type artist name and click +',
      placeholder: 'Enter singers & artists name',
      notifySlack: 'residentsuggestions',
      defaultValue: [],
    },
    memories: {
      type: 'TEXTAREA',
      label: 'Music memories',
      subLabel: 'Do they have any specific music memories? Do they remember any music from their childhood?'
    }
  }
}