import { ResidentPreferenceConfig } from '../models/model';

export const sportsConfig: ResidentPreferenceConfig = {
  order: 5,
  header: {
    title: 'Sports %firstName% likes',
    icon: 'sports_basketball',
    button: {
      icon: 'edit_note',
      label: 'Edit'
    }
  },
  fields: {
    sports: {
      cardTitle: 'Sports',
      type: 'CHIPS',
      multiple: true,
      query: 'getCategories',
      queryParams: {
        slug: '/sport',
        type: 'VIDEO',
      },
      label: 'What types of sports do they like to watch or play?',
      subLabel: 'Select all that apply',
      defaultValue: [],
    },
    suggestedSports: {
      cardTitle: 'Other sports',
      type: 'CHIP_INPUT',
      label: 'Can’t find your favourite sport?',
      subLabel: 'Type the sport name and click +',
      placeholder: 'Enter sport name',
      notifySlack: 'residentsuggestions',
      defaultValue: [],
    },
    memories: {
      type: 'TEXTAREA',
      label: 'Thoughts & memories',
      subLabel: 'Do they have special memories, triumphs? Favourite sporting teams?'
    }
  }
}